import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import router from './router'
import VueMeta from 'vue-meta'
import VueSilentbox from 'vue-silentbox'
import BackToTop from 'vue-backtotop'
import dashify from 'dashify';

const MyPlugin = {
    install(Vue, options) {
        Vue.prototype.slug = (str) => {
            return dashify(str,{condense: true});
        }
    },
}
Vue.use(MyPlugin)

Vue.use(VueSilentbox)
Vue.use(BootstrapVue)
Vue.use(BackToTop)
Vue.use(VueAwesomeSwiper)
Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
})

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../src/assets/css/font-awesome.min.css'
import '../src/assets/css/material-design-iconic-font.min.css'
import '../src/assets/css/ionicons.min.css'
import '../src/assets/css/flaticon.min.css'
import 'swiper/css/swiper.min.css'
import '../src/assets/css/animate.min.css'
import '../src/assets/scss/style.scss'

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')

Vue.prototype.apiUrlBase            = 'https://api.themoviedb.org/3/';
Vue.prototype.apiUrl                = 'https://api.themoviedb.org/3/movie/';
Vue.prototype.apiEndPoint           = '?api_key=8695490184bebb0c66a202ee27e8e8c1&language=tr';
Vue.prototype.baseImageOriginalUrl  = 'https://image.tmdb.org/t/p/original/';
Vue.prototype.baseImageUrl          = 'https://image.tmdb.org/t/p/';
